export const USER_ORDER_TABLE_OPTIONS = () => {
  return {
    name: 'История заказов',
    id: 'order',
    request: '/api/order/list/my',
    variables: ['number', 'createdAt', 'status', 'track', 'deliveryDate', 'amount'],
    actions: {
      more: true
    },
    limit: 12,
    search: true,
    header: [
      { id: "number", name: "#", width: "10%" },
      { id: "createdAt", name: "Дата", width: "10%" },
      { id: "status", name: "Статус", width: "20%" },
      { id: "track", name: "Трек номер", width: "15%" },
      { id: "deliveryDate", name: "Дата доставки", width: "15%" },
      { id: "amount", name: "Стоимость", width: "10%" },
      { id: "", name: "", width: "6%" },
    ]
  }
}

export const USER_ANALYTICS_TABLE_OPTIONS = () => {
  return {
    name: 'Аналитика',
    id: 'analytics',
    request: '/api/order/referral/list',
    variables: ['orderNumberDate', 'quantityOrder', 'priceOrder', 'deliveryOrder', 'amountOrder', 'discount', 'amountWithDiscount', 'bonus'],
    actions: {},
    search: true,
    header: [
      { id: "orderNumberDate", name: "Заказ", width: "20%" },
      { id: "quantityOrder", name: "Кол-во", width: "10%" },
      { id: "priceOrder", name: "Цена", width: "12%" },
      { id: "deliveryOrder", name: "Доставка", width: "12%" },
      { id: "amountOrder", name: "Сумма", width: "12%" },
      { id: "discount", name: "Скидка", width: "12%" },
      { id: "amountWithDiscount", name: "Сумма со скидкой", width: "15%" },
      { id: "bonus", name: "Бонус", width: "10%" },
    ]
  }
}

export const USER_CLIENT_TABLE_OPTIONS = () => {
  return {
    name: 'Клиенты',
    id: 'client',
    request: '/api/user/referral/list',
    variables: ['name', 'orderLength', 'orderAmount', 'referralAmount'],
    actions: {},
    search: true,
    header: [
      { id: "name", name: "ФИО", width: "30%" },
      { id: "orderLength", name: "Кол-во заказов", width: "20%" },
      { id: "orderAmount", name: "Сумма заказов, ₽", width: "20%" },
      { id: "referralAmount", name: "Мой процент, ₽", width: "20%" },
    ]
  }
}

export const ADMIN_CATALOG_TABLE_OPTIONS = () => {
  return {
    name: 'Каталог товаров',
    id: 'admin-catalog',
    request: '/api/product/list/admin',
    variables: ['image', 'category', 'name', 'type', 'unitsPerPack', 'pricePerUnit', 'pricePerPack', 'weight', 'description'],
    actions: {
      add: true,
      edit: true,
      delete: true
    },
    limit: 8,
    search: true,
    header: [
      { id: "image", name: "Фото", width: "8%" },
      { id: "category", name: "Категория", width: "11%" },
      { id: "name", name: "Название", width: "11%" },
      { id: "type", name: "Тип", width: "10%" },
      { id: "unitsPerPack", name: "Ед. в уп.", width: "8%" },
      { id: "pricePerUnit", name: "Стоимость за ед.", width: "12%" },
      { id: "pricePerPack", name: "Стоимость за уп.", width: "10%" },
      { id: "weight", name: "Вес, г", width: "8%" },
      { id: "description", name: "Описание", width: "14%" },
      { id: "", name: "", width: "6%" },
    ]
  }
}

export const ADMIN_CATEGORY_TABLE_OPTIONS = () => {
  return {
    name: '',
    id: 'admin-category',
    request: '/api/category/list',
    variables: ['name'],
    limit: 8,
    actions: {
      add: true,
      edit: true,
      delete: true
    },
    search: true,
    header: [
      { id: "name", name: "Название", width: "80%" },
      { id: "", name: "", width: "20%" },
    ]
  }
}

export const ADMIN_ORDER_TABLE_OPTIONS = () => {
  return {
    name: 'Заказы',
    id: 'admin-order',
    request: '/api/order/list',
    variables: ['number', 'user__name', 'user__phone', 'user__email', 'productLength', 'createdAt', 'status', 'delivery__address', 'delivery__track', 'amount'],
    actions: {
      more: true,
    },
    search: true,
    limit: 15,
    header: [
      { id: "number", name: "#", width: "5%" },
      { id: "user__name", name: "ФИО", width: "10%" },
      { id: "user__phone", name: "Телефон", width: "10%" },
      { id: "user__email", name: "Email", width: "10%" },
      { id: "productLength", name: "Позиции", width: "8%" },
      { id: "createdAt", name: "Дата заказа", width: "10%" },
      { id: "status", name: "Статус", width: "10%" },
      { id: "delivery__address", name: "Адрес доставки", width: "15%" },
      { id: "delivery__track", name: "Трек номер", width: "10%" },
      { id: "amount", name: "Стоимость", width: "10%" },
    ]
  }
}

export const ADMIN_PARTNER_TABLE_OPTIONS = () => {
  return {
    name: 'Партнеры',
    id: 'admin-partner',
    request: '/api/user/partner/list',
    variables: ['name', 'email', 'finance__name', 'finance__amount', 'createdAt', 'verification'],
    actions: {
      edit: true,
    },
    search: true,
    limit: 12,
    header: [
      { id: "name", name: "ФИО", width: "25%" },
      { id: "email", name: "Почта", width: "15%" },
      { id: "finance__name", name: "Компания", width: "20%" },
      { id: "finance__amount", name: "Баланс", width: "10%" },
      { id: "createdAt", name: "Дата регистр.", width: "10%" },
      { id: "verification", name: "Верификация", width: "10%" },
      { id: "", name: "", width: "10%" },
    ]
  }
}

export const ADMIN_CLIENT_TABLE_OPTIONS = () => {
  return {
    name: 'Клиенты',
    id: 'admin-client',
    request: '/api/user/list?role=user',
    variables: ['name', 'phone', 'email', 'city', 'orderLength', 'orderAmount', 'partner', 'createdAt', 'referrer'],
    actions: {},
    search: true,
    header: [
      { id: "name", name: "ФИО", width: "10%" },
      { id: "phone", name: "Телефон", width: "10%" },
      { id: "email", name: "Email", width: "6%" },
      { id: "city", name: "Город", width: "8%" },
      { id: "orderLength", name: "Кол-во заказов", width: "8%" },
      { id: "orderAmount", name: "Стоимость заказов", width: "8%" },
      { id: "partner", name: "Партнер", width: "8%" },
      { id: "createdAt", name: "Дата регистр.", width: "8%" },
      { id: "referrer", name: "Участник реф. прогр.", width: "10%" },
      { id: "", name: "", width: "8%" },
    ]
  }
}

export const ADMIN_STORE_PRODUCT_TABLE_OPTIONS = () => {
  return {
    name: 'Товары на складах',
    id: 'admin-store-product',
    request: '/api/store/product/list',
    variables: ['image', 'category', 'name', 'pricePerUnit', 'pricePerPack', 'store', 'quantity'],
    actions: {
      add: true,
      edit: true,
      delete: true
    },
    limit: 8,
    search: true,
    header: [
      { id: "image", name: "Фото", width: "6%" },
      { id: "category", name: "Категория", width: "10%" },
      { id: "name", name: "Название", width: "14%" },
      { id: "pricePerUnit", name: "Стоимость за ед.", width: "10%" },
      { id: "pricePerPack", name: "Стоимость за уп.", width: "10%" },
      { id: "store", name: "Адрес склада", width: "8%" },
      { id: "quantity", name: "Кол-во на складе", width: "10%" },
      { id: "", name: "", width: "6%" },
    ]
  }
}

export const ADMIN_STORE_TABLE_OPTIONS = () => {
  return {
    name: 'Склады',
    id: 'admin-store',
    request: '/api/store/list',
    variables: ['address'],
    limit: 8,
    actions: {
      add: true,
      edit: true,
      delete: true
    },
    search: true,
    header: [
      { id: "address", name: "Адрес", width: "80%" },
      { id: "", name: "", width: "20%" },
    ]
  }
}

export const ADMIN_STAFF_TABLE_OPTIONS = () => {
  return {
    name: 'Настройки (управление сотрудниками)',
    id: 'admin-staff',
    request: '/api/user/staff/pag',
    variables: ['avatar', 'name', 'email', 'orderLength', 'orderAmount'],
    actions: {
      add: true,
      edit: true,
      delete: true
    },
    search: true,
    header: [
      { id: "avatar", name: "Фото", width: "10%" },
      { id: "name", name: "ФИО", width: "20%" },
      { id: "email", name: "Email", width: "15%" },
      { id: "orderLength", name: "Кол-во обработ. заказов", width: "20%" },
      { id: "orderAmount", name: "Сумма обработ. заказов", width: "20%" },
      { id: "", name: "", width: "10%" },
    ]
  }
}