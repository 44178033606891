
import { defineComponent } from "vue-demi";
import { EditIcon, TrashIcon, CheckMarkIcon, DownArrowIcon} from "@/plugins/icons";
import { dynamicsObject } from "@/interfaces";
import { Order } from "@/interfaces/order/order.dto";
import { PRODUCT_TYPES, STATUS_TYPES } from "@/utils/enums";
import ContainerBodyElement from "./ContainerBodyElement.vue";
import { Cart } from "@/interfaces/cart/cart.dto";

export default defineComponent({
  name: "TableContainerBody",
  props: {
    options: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showRow: null as dynamicsObject | null,
    }
  },
  methods: {
    currentPriceProduct(product: Cart.Dto['products'][number]) {
      const price = product.product?.type === PRODUCT_TYPES.UNIT ? product.product?.pricePerUnit : product.product?.pricePerPack;
      if (!price) return '--';
      return product.quantity * price;
    },
    modal(id: string, data: dynamicsObject) {
      this.$store.commit('createModal', { id, data });
    },
    isOrderNotProcessed(order: Order.Dto) {
      return this.options.id === 'admin-order' && order.status === STATUS_TYPES.PAID;
    },
    onShowRow(data: dynamicsObject) {
      console.log(data);
      if (this.showRow === data._id) return this.showRow = null;
      this.showRow = data._id;
    },
    onPrintRow(order: dynamicsObject) {
      console.log(order);
      let quantity = 0;
      const d = new Date(order.createdAt);
      let html = `
        <!DOCTYPE html>
        <html>
          <head>
              <meta charset="utf-8">
              <title>Печать заказа</title>
             <style>
              table {
                  width: 100%;
                  box-sizing: border-box;
                  width: 100%;
                  border-collapse: collapse;
                  text-align: center;
              }
              table th,
              table td {
                  border: 1px solid;
                  padding: 5pt 10pt;
              }
              table .result td {
                  border: 1px transparent;
                  padding-top: 10pt;
              }
              html {
                font-size: 12pt;
              }
              .person {
                display: flex;
                gap: 15px;
                flex-direction: row;
                flex-wrap: nowrap;
                margin-bottom: 1em;
              }
            </style>
          </head>
          <body onload='window.focus(); window.print();'>
      `;
      html += `<h1 style="text-align: center;">Заказ №${order.number} от ${("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)}</h1>`;
      html += `<div class="person">
        <div class="left"><b>Плательщик:</b></div>
        <div class="right">${(order.user) ? order.user.name : order.name}<br>${order.phone}<br>${(order.user) ? order.user.email : ''}</div>
      </div>`;
      html += `<table class="torder">`;
      html += `<tr class="head"><th width="5%">№</th><th width="55%" style="text-align: left;">Наименование</th><th width="20%">Количество</th><th width="20%">Цена</th></tr>`;
      order.products.forEach(function(item: any, index: number) {
        quantity += Number(item.quantity);
        html += `<tr class="item product"><td>${index+1}</td><td style="text-align: left;">${item.product.name}</td><td>${item.quantity}</td><td>${item.product.pricePerPack} ₽</td></tr>`;
      });
      if (order.delivery) {
        html += `<tr class="item delivery"><td colspan="3" style="text-align: left;">${order.delivery.city}, ${order.delivery.address}</td><td>${order.delivery.price} ₽</td></tr>`;
      }
      if (order.promocode) {
        html += `<tr class="item delivery"><td colspan="3" style="text-align: left;">Промокод</td><td>10%</td></tr>`;
      }
      if (order.delivery_addres) {
        html += `<tr class="item delivery"><td colspan="4" style="text-align: left;">${order.delivery_addres}</td></tr>`;
      }
      html += `<tr class="result"><td colspan="2" style="text-align: right;"><b>ИТОГО:</b></td><td><b>${quantity}</b></td><td><b>${order.amount} ₽</b></td></tr>`;
      html += `</table>`;
      html += `</body></html>`;

      window.open(URL.createObjectURL(new Blob([html], { type: "text/html" })), '', "right=50,top=50,width=900,height=640,toolbar=0,scrollbars=1,status=0");
    }
  },
  components: {
    ContainerBodyElement,
    EditIcon,
    CheckMarkIcon,
    TrashIcon,
    DownArrowIcon,
  },
});
